import { render, staticRenderFns } from "./GlobalSearchResults.vue?vue&type=template&id=0fcf19a1&scoped=true&"
import script from "./GlobalSearchResults.vue?vue&type=script&lang=js&"
export * from "./GlobalSearchResults.vue?vue&type=script&lang=js&"
import style0 from "./GlobalSearchResults.vue?vue&type=style&index=0&id=0fcf19a1&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fcf19a1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VList,VListItem,VListItemContent,VListItemGroup,VListItemSubtitle,VListItemTitle,VSubheader})
